.login-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    padding: 20px;
    background-color: #000;
    background-size: cover;
    background-position: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
}

.form-container {
    border-radius: 5px;
    z-index: 1;
}

.form-container h1 {
    margin-bottom: 50px;
    font-size: 2.5rem;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    background-color: transparent;
    border: none;
    width: 100%;
    max-width: 290px;
    padding: 10px;
    font-size: 16px;
    border-bottom: 3px solid #ccc;
    color: white;
    outline: none;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.btn {
    background-color: #fff !important;
    color: #007bff;
    padding: 15px 45px;
    font-size: 20px;
    border: 1px solid #007bff;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 30px;
}

.btn:hover {
    background-color: #f2f2f2;
}

.note {
    margin-top: 50px;
}

.footer {
    margin-top: 50px;
}

.footer h1 {
    font-size: 2.5rem;
    letter-spacing: 3px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: -20px;
}

.footer h2 {
    font-size: 1.5rem;
    color: #ccc;
}